.popup {
    position: fixed;
    top: 1%;
    left: 1%;
    right: 1%;
    bottom: 1%;
    background-color: black;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 98vw;
    height: 98vh;
  }
  
  .carousel-custom {
    width: 100%;
    height: 60vh;
    min-height: 60vh;
  }

  .carousel{
    width: 100%;
    height: 60vh;
    min-height: 60vh;
  }
  
  .carousel-player {
    width: 100%;
    min-height: 60vh !important;
  }
  
  @media (max-width: 768px) {
    .popup {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 0;
      width: 100vw;
      height: 100vh;
    }
  
    .carousel-custom {
      min-height: 50vh !important;
    }
  
    .carousel-player {
      min-height: 50vh !important;
    }
  }
  