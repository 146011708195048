.framer-section {
}

.projects-title {
  font-family: "SAM";
  font-size: 8rem;
  color: transparent;
  -webkit-text-stroke: 1px white;
  text-stroke: 1px white;
}

@media (min-width: 768px) {
  .projects-title {
    font-size: 18rem;
  }
}