.nav-bar-item{
    font-family: 'Lexend', sans-serif;
    font-size: 2.5em;
    color: white;
}

.nav-bar-item:hover{
    color: #00FFCC;
}

.nav-bar-logo{
    font-family: 'SAM', sans-serif;
    font-size: 1.5em;
    font-weight: 100;
    color: white;
}