.sam-intro-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.sam-background-logo {
  width:800px;
  position: absolute;
  top:10%;
  z-index: -1;
}

@media screen and (max-width: 800px) {
   .sam-title-container {
    display: flex;
    align-items: flex-start;
  }
}

.sam-title-container {
  display: flex;
  align-items: flex-start;
}

.sam-title {
  font-family: "SAM";
  font-size: 30rem;
  color: white;
  margin-left: 20rem;
  padding-top: 30%;
}

.sam-leftTitle {
  margin-left: 2rem;
  margin-top: 17rem;
  line-height: 150%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 30%;
}

.sam-leftTitle-text {
  font-family: "Lexend";
  font-size: 2.5rem;
  color: #00FFCC;
  margin: 0;
}

.sam-circle {
  width: 15rem;
  height: 15rem;
}

.sam-text-container {
  display: flex;
  align-items: center;
  margin-top: 10rem;
}

.sam-text {
  font-family: "SAM";
  font-size: 6rem;
  color: white;
  margin: 0 1rem;
  padding-bottom: 10rem
}

.sam-bullet {
  font-size: 3rem;
  color: #00FFCC;
  margin: 0 1rem;
  padding-bottom: 10rem
}

.centered-text {
  font-family: "Lexend", sans-serif;
  /* font-size: 70px; */
  color: white;
}

.centered-text-strong {
  color: #00FFCC;
  font-family: "SAM", sans-serif;
  font-weight: 200 !important;
}

/* Ajout de classes Tailwind pour rendre le composant responsive */

@media screen and (max-width: 1500px) {
  .sam-title {
    font-size: 20rem;
    margin-left: 10rem;
  }

  .sam-leftTitle {
    margin-top: 10rem;
  }

  .sam-leftTitle-text {
    font-size: 2rem;
  }

  .sam-text {
    font-size: 4rem;
  }

  .sam-bullet {
    font-size: 2rem;
  }

  .sam-background-logo {
    position: absolute;
    top: 18%;
    z-index: -1;
    width: 500px;
  }
  .centered-text {
    /* font-size: 2rem; */
  }
}

@media screen and (max-width: 768px) {
  .sam-title {
    font-size: 15rem;
    margin-left: 5rem;
  }

  .sam-leftTitle {
    margin-top: 5rem;
  }

  .sam-leftTitle-text {
    font-size: 1.5rem;
  }

  .sam-text {
    font-size: 3rem;
  }

  .sam-bullet {
    font-size: 1.5rem;
  }

  .centered-text {
    /* font-size: 25px; */
  }
}

@media screen and (max-width: 480px) {
  .sam-title {
    font-size: 10rem;
    margin-left: 2rem;
  }

  .sam-leftTitle {
    /* margin-top: 2rem; */
    line-height: 10px;
  }

  .sam-leftTitle-text {
    font-size: 1rem;
  }

  .sam-text {
    font-size: 2rem;
  }

  .sam-bullet {
    font-size: 1rem;
  }

  .centered-text {
    /* font-size: 30px; */
  }
}

/* Ajout de classes Tailwind pour conserver les proportions */

.sam-intro-container {
  background-size: 100%;
  background-position: center top 35%;
}

.sam-title {
  margin-left: 8rem;
  margin-top: 0rem;
}

.sam-leftTitle {
  margin-left: 1rem;
}

.sam-leftTitle-text {
  margin: 0;
}

.sam-text-container {
  margin-top: 10rem;
}

.sam-text {
  margin: 0 1rem;
}

.sam-bullet {
  margin: 0 1rem;
}

.centered-text {
  margin-top: 10rem;
}