/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@font-face {
  font-family: 'SAM';
  src: local('SAM'), url(/public/fonts/Sam-studio.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Lexend';
  src: local('Lexend'), url(/public/fonts/LexendDeca.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.App {
  background-image: url('/public/img/background.webp');
  background-position: center;
  background-attachment: fixed;
}

.App-loading {
  /* background-image: url('/public/img/background.jpg'); */
  background: black;
  background-position: center;
  background-attachment: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
}


html {
  scroll-snap-type: y proximity;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.scroll-pos {
  scroll-snap-align: center;
  perspective: 500px;
}

body{
  cursor: url('/public/img/size_ver.cur'), auto;
}

body::-webkit-scrollbar {
  display: none;
}


:root {
  cursor: none;
  --cursorX: 50vw;
  --cursorY: 50vh;
}
:root:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  pointer-events: none;
  background: radial-gradient(
    circle 10vmax at var(--cursorX) var(--cursorY),
    rgba(0,0,0,0) 0%,
    rgba(0,0,0,.5) 80%,
    rgba(0,0,0,.95) 100%
  )
}