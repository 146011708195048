.grid-border {
  border-right: 1px solid white;
  border-top: 1px solid white;
  border-bottom: 1px solid #00FFCC;
  border-left: 1px solid #00FFCC;
  border-radius: 5px;
  padding: 15px;
  width: auto;
  height: auto;
  position: relative;
  max-height: 50vh;
  margin: 10vw;
}

.wework-title {
  padding-top: 10vh;
  font-family: 'Lexend';
  font-weight: 600;
  font-size: 55pt;
  position: relative;
  margin-left: 15%;
}

.wework-title:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0%;
  height: 10px;
  width: 80%;
  border-bottom: 1px solid white;
}

.wework-title::after {
  content: "";
  position: absolute;
  top: 96.5%;
  right: 21%;
  width: 1px;
  height: 18px;
  background-color: white;
}
