.sam-logo-text {
  font-family: "SAM";
  font-size: 6rem;
  color: white;
  margin: 0 1rem;
  position: absolute;
  font-size: 4vw; /* Use vw units for font size */
}

.origin::before {
  content: "";
  position: absolute;
  inset: 0;
  background-image: url('/public/img/rond.png');
  background-size: cover;
  background-position: center;
}

.text-strong {
  color: #00ffcc;
  font-family: "SAM", sans-serif;
  font-weight: 200 !important;
}

.logo-div{
  left: 50%;
  transform: translateX(-50%) translateY(-15%);
  top:20%;
  width: 100vw; /* Use vw units for width */
  max-width: 80rem; /* Set a maximum width */
}

.footer-custom{
  display:inline-flex;
  position: absolute;
  margin-top: 190vh;
  width: 100%;
  padding-left: 1%;
  padding-right: 1%;
}

@media screen and (max-width: 768px) {
  .sam-logo-text {
    font-size: 3rem;
  }
}

@media screen and (max-width: 480px) {
  .sam-logo-text {
    font-size: 2rem;
  }
}
