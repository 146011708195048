.framer {
    border: 10px solid #fff;
    border-radius: 10px;
    width: 90vw;
    height: 80vh;
    position: absolute;
}

::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: var(--background);
}

::-webkit-scrollbar-thumb {
    background: var(--accent);
    -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
    background: var(--background);
}

.frame-section {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.frame-section>div {
    width: 100%;
    height: 100%;
    position: relative;
    max-height: 90vh;
    margin: 20px;
    background: var(--white);
    overflow: hidden;
    :hover {
        cursor: pointer;
    }
}

.project-image {
    position: absolute;
    top: 10%;
    left: 10%;
    right: 10%;
    bottom: 10%;
    width: 80%;
    height: 80%;
    transition: transform 0.3s ease-in-out;
    object-fit: cover;
}

.project-image:hover {
    transform: scale(1.1);
}


.frame-title {
    font-family: 'Lexend';
    font-size: 10rem;
    font-weight: 700;
    color: #00FFCC;
    position: absolute;
    align-items: center;
}

.frame-title-secondary {
    font-family: 'Lexend';
    font-size: 10rem;
    font-weight: 700;
    color: #00FFCC;
    opacity: 0.2;
    position: absolute;
    align-items: center;
}

.project-image-overlay {
    position: absolute;
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.project-image-overlay:hover {
    background-color: rgba(0, 0, 0, 0.0);
}

.project-image-border img {
    border-right: 1px solid white;
    border-top: 1px solid white;
    border-bottom: 1px solid #00FFCC;
    border-left: 1px solid #00FFCC;
    border-radius: 5px;
    padding: 15px;
}